import Vue from "vue";
import Router from "vue-router";
import Store from "./store/store.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes        // Theme Routes

        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue")
        },
        {
          path: "/hr-report/attendance",
          name: "attendance",
          component: () => import("@/views/hr-report/attendance/Attendance.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Attendance", active: true }
            ],
            pageTitle: "Attendance",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/time-attendance",
          name: "time-attendance",
          component: () => import("@/views/hr-report/time-attendance/TimeAttendance.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Time Attendance", active: true }
            ],
            pageTitle: "Time Attendance",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/main-report",
          name: "main-report",
          component: () => import("@/views/hr-report/main-report/MainReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Main Report", active: true }
            ],
            pageTitle: "Main Report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/all-report",
          name: "all-report",
          component: () => import("@/views/hr-report/all-report/AllReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "All in one report", active: true }
            ],
            pageTitle: "All in one report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/hr-request-report",
          name: "hr-request-report",
          component: () => import("@/views/hr-report/hr-request-report/HRRequestReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "HR request report", active: true }
            ],
            pageTitle: "HR request report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/penalty-report",
          name: "penalty-report",
          component: () => import("@/views/hr-report/penalty-report/PenaltyReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "penalty Report" },
              { title: "penalty report", active: true }
            ],
            pageTitle: "penalty report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/daily-report",
          name: "daily-report",
          component: () => import("@/views/hr-report/daily-report/dailyReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "daily Report" },
              { title: "daily report", active: true }
            ],
            pageTitle: "daily report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/vacation-report",
          name: "vacation-report",
          component: () => import("@/views/hr-report/vacation-report/VacationReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Vacation Report", active: true }
            ],
            pageTitle: "Vacation Report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/excuse-report",
          name: "excuse-report",
          component: () => import("@/views/hr-report/excuse-report/ExcuseReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Excuse Report", active: true }
            ],
            pageTitle: "Excuse Report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/mission-report",
          name: "mission-report",
          component: () => import("@/views/hr-report/mission-report/MissionReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Mission Report", active: true }
            ],
            pageTitle: "Mission Report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/absence-report",
          name: "absence-report",
          component: () => import("@/views/hr-report/absence-report/AbsenceReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Absence Report", active: true }
            ],
            pageTitle: "Absence Report",
            rule: "editor"
          }
        },
        // {
        //   path: "/hr-report/working-lessthan-6hrs-report",
        //   name: "working-lessthan-6hrs-report",
        //   component: () => import("@/views/hr-report/working-lessthan-6hrs-report/WorkingLessthan6Report.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "HR Report" },
        //       { title: "Working Less Than 6 Hours Report", active: true }
        //     ],
        //     pageTitle: "Working Less Than 6 Hours Report",
        //     rule: "editor"
        //   }
        // },
        {
          path: "/hr-report/sammry-report",
          name: "summary-report",
          component: () => import("@/views/hr-report/sammry-report/SammryReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Summary Report", active: true }
            ],
            pageTitle: "Summary Report",
            rule: "editor"
          }
        },
        {
          path: "/hr-report/variables-report",
          name: "variables-report",
          component: () => import("@/views/hr-report/variables-report/VariablesReport.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "HR Report" },
              { title: "Variables Report", active: true }
            ],
            pageTitle: "Variables Report",
            rule: "editor"
          }
        },
        {
          path: "/erp-reports/erp-reports",
          name: "erp-reports",
          component: () => import("@/views/erp-reports/ErpReports.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "ERP Report" },
              { title: "ERP Reports", active: true }
            ],
            pageTitle: "ERP Reports",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/TopManagement",
          name: "get-topmanagement",
          component: () => import("@/views/TopManagement/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "TopManagement" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "TopManagement",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Policies",
          name: "get-policies",
          component: () => import("@/views/Policies/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Policies" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Company Policies",
            rule: "editor"
          }
        },
        {
          path: "/Add/Policies",
          name: "add-policies",
          component: () => import("@/views/Policies/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Policies", url: "/Add/Policies" },
              { title: "Add", active: true }
            ],
            pageTitle: "Add Policies",
            rule: "editor"
          }
        },
        {
          path: "/Update/Policies",
          name: "update-policies",
          component: () => import("@/views/Policies/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Policies", url: "/Update/Policies" },
              { title: "Update", active: true }
            ],
            pageTitle: "Update Policies",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Time",
          name: "get-time",
          component: () => import("@/views/Time/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Time", },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Time",
            rule: "editor"
          }
        },
        {
          path: "/Add/Time",
          name: "add-time",
          component: () => import("@/views/Time/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Time", url: "/GetAll/Time" },
              { title: "Send Time", active: true }
            ],
            pageTitle: "Send Time",
            rule: "editor"
          }
        },
        {
          path: "/Update/Time",
          name: "update-time",
          component: () => import("@/views/Time/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Time", url: "/GetAll/Time" },
              { title: "Update Time", active: true }
            ],
            pageTitle: "Update Time",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/News",
          name: "get-news",
          component: () => import("@/views/News/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "News", },
              { title: "GetAll", active: true }
            ],
            pageTitle: "News",
            rule: "editor"
          }
        },
        {
          path: "/Add/News",
          name: "add-news",
          component: () => import("@/views/News/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "News", url: "/GetAll/News" },
              { title: "Send News", active: true }
            ],
            pageTitle: "Send News",
            rule: "editor"
          }
        },
        {
          path: "/Update/News",
          name: "update-news",
          component: () => import("@/views/News/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "News", url: "/GetAll/News" },
              { title: "Update News", active: true }
            ],
            pageTitle: "Update News",
            rule: "editor"
          }
        },
        {
          path: "/Update/Employee",
          name: "update-employee",
          component: () => import("@/views/User/Employee/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Employee", url:"/GetAll/Employee"},
              { title: "Update Employee", active: true }
            ],
            pageTitle: "Update Employee",
            rule: "editor"
          }
        },
        {
          path: "/Add/Employee",
          name: "add-employee",
          component: () => import("@/views/User/Employee/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Employee", url:"/GetAll/Employee"},
              { title: "Add Employee", active: true }
            ],
            pageTitle: "Add Employee",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Employee",
          name: "get-employees",
          component: () => import("@/views/User/Employee/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Employee" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Employees",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Employee/manager-replacement",
          name: "manager-replacement",
          component: () => import("@/views/User/Employee/ManagerReplacement.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Employee", url: "/GetAll/Employee" },
              { title: "Manager replacement", active: true }
            ],
            pageTitle: "Manager replacement",
            rule: "editor"
          }
        },
        {
          path: "/Update/Company",
          name: "update-company",
          component: () => import("@/views/User/Company/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "home", url: "/" },
              { title: "Company", url: "/GetAll/Company" },
              { title: "Update Company", active: true }
            ],
            pageTitle: "Update Company",
            rule: "editor"
          }
        },
        {
          path: "/Add/Company",
          name: "add-company",
          component: () => import("@/views/User/Company/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Company", url: "/GetAll/Company" },
              { title: "Add Company", active: true }
            ],
            pageTitle: "Add Company",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Company",
          name: "get-companies",
          component: () => import("@/views/User/Company/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Company" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Companies",
            rule: "editor"
          }
        },
        {
          path: "/Update/Department",
          name: "update-department",
          component: () => import("@/views/User/Department/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Department", url: "/GetAll/Department" },
              { title: "Update Department", active: true }
            ],
            pageTitle: "Update Department",
            rule: "editor"
          }
        },
        {
          path: "/Add/Department",
          name: "add-department",
          component: () => import("@/views/User/Department/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Department", url: "/GetAll/Department" },
              { title: "Add Department", active: true }
            ],
            pageTitle: "Add Department",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Department",
          name: "get-departments",
          component: () => import("@/views/User/Department/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Department" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Departments",
            rule: "editor"
          }
        },
        {
          path: "/Update/Job",
          name: "update-job",
          component: () => import("@/views/User/Job/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Job", url: "/GetAll/Job" },
              { title: "Update Job", active: true }
            ],
            pageTitle: "Update Job",
            rule: "editor"
          }
        },
        {
          path: "/Add/Job",
          name: "add-job",
          component: () => import("@/views/User/Job/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Job", url: "/GetAll/Job" },
              { title: "Add Job", active: true }
            ],
            pageTitle: "Add Job",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Job",
          name: "get-jobs",
          component: () => import("@/views/User/Job/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Job" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Jobs",
            rule: "editor"
          }
        },
        {
          path: "/Add/Category",
          name: "add-category",
          component: () => import("@/views/User/Category/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Category", url: "/GetAll/Category" },
              { title: "Add Category", active: true }
            ],
            pageTitle: "Add Category",
            rule: "editor"
          }
        },
        {
          path: "/Update/Category",
          name: "update-category",
          component: () => import("@/views/User/Category/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Category", url: "/GetAll/Category" },
              { title: "Update Category", active: true }
            ],
            pageTitle: "Update Category",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Category",
          name: "get-categories",
          component: () => import("@/views/User/Category/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Category" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Categories",
            rule: "editor"
          }
        },
        {
          path: "/Add/FAQ",
          name: "add-FAQ",
          component: () => import("@/views/FAQ/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQ", url: "/Add/FAQ" },
              { title: "Add", active: true }
            ],
            pageTitle: "Add FAQ",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/FAQ",
          name: "get-FAQ",
          component: () => import("@/views/FAQ/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQ" },
              { title: "GetAll", active: true }
            ],
            pageTitle: " FAQ",
            rule: "editor"
          }
        },
        {
          path: "/Update/FAQ",
          name: "update-FAQ",
          component: () => import("@/views/FAQ/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQ", url: "/Update/FAQ" },
              { title: "Update", active: true }
            ],
            pageTitle: "Update FAQ",
            rule: "editor"
          }
        },
        {
          path: "/Location/GetAll/City",
          name: "get-city",
          component: () => import("@/views/Location/City/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "City" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "City",
            rule: "editor"
          }
        },
        {
          path: "/Location/Add/City",
          name: "add-city",
          component: () => import("@/views/Location/City/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "City", url: "/Location/GetAll/City" },
              { title: "Add", active: true }
            ],
            pageTitle: "Add City",
            rule: "editor"
          }
        },
        {
          path: "/Location/Update/City",
          name: "update-city",
          component: () => import("@/views/Location/City/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "City", url: "/Location/GetAll/City" },
              { title: "Update", active: true }
            ],
            pageTitle: "Update City",
            rule: "editor"
          }
        },
        {
          path: "/Location/GetAll/Areas",
          name: "get-areas",
          component: () => import("@/views/Location/Areas/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "Areas" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Areas",
            rule: "editor"
          }
        },
        {
          path: "/Location/Add/Areas",
          name: "add-area",
          component: () => import("@/views/Location/Areas/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "Areas", url: "/Location/GetAll/Areas" },
              { title: "Add", active: true }
            ],
            pageTitle: "Add Area",
            rule: "editor"
          }
        },
        {
          path: "/Location/Update/Areas",
          name: "update-area",
          component: () => import("@/views/Location/Areas/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Location" },
              { title: "Areas", url: "/Location/GetAll/Areas" },
              { title: "Update", active: true }
            ],
            pageTitle: "Update Area",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Notification",
          name: "get-notifications",
          component: () => import("@/views/Notification/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Notification" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Notifications",
            rule: "editor"
          }
        },
        {
          path: "/Add/Notification",
          name: "add-notification",
          component: () => import("@/views/Notification/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Notification", url: "/GetAll/Notification" },
              { title: "Send Message", active: true }
            ],
            pageTitle: "Send Message",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Reporting_to",
          name: "get-reporting-to",
          component: () => import("@/views/Administrator/reporting-to/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Reporting-To" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Reporting-To",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/SignInOut_logs",
          name: "get-sign-in-out-logs",
          component: () => import("@/views/Administrator/sign-in-out/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Sign-In-Out-Log" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Sign-In-Out-Log",
            rule: "editor"
          }
        },

        {
          path: "/Add/Roles",
          name: "add-roles",
          component: () => import("@/views/Administrator/roles/Add.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Roles", url: "/GetAll/Roles" },
              { title: "Add Roles", active: true }
            ],
            pageTitle: "Add Roles",
            rule: "editor"
          }
        },
        {
          path: "/Update/Roles",
          name: "update-roles",
          component: () => import("@/views/Administrator/roles/Update.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Roles", url: "/GetAll/Roles" },
              { title: "Update Roles", active: true }
            ],
            pageTitle: "Update Roles",
            rule: "editor"
          }
        },
        {
          path: "/GetAll/Roles",
          name: "get-roles",
          component: () => import("@/views/Administrator/roles/GetAll.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Roles" },
              { title: "GetAll", active: true }
            ],
            pageTitle: "Roles",
            rule: "editor"
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/pages/Login.vue")
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue")
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/pages/error-404" ||
    to.path === "/pages/error-500"
    // to.path === "/pages/register" ||
    // to.path === "/callback" ||
  ) {
    return next();
  }

  // If auth required, check login. If login fails redirect to login page

  if (
    !Store.state.auth.isUserLoggedIn() &&
    to.path !== "/login" &&
    from.path !== "/login"
  ) {
    router.push({ name: "login", query: { to: to.path } });

  }
  if (Store.state.auth.isUserLoggedIn() && to.path === "/login") {
    router.push({ name: "home", query: { to: to.path } });
  }
  return next();

});

export default router;
