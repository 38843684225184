import Vue from 'vue'
import store from '../store/store'


Vue.directive('can',
    function(el, binding, vnode) {
      let permissions = store.state.auth.permissions.length ? store.state.auth.permissions  : localStorage.getItem('permissions');
      if(binding.arg == "permission"){
      
         if(permissions && permissions.indexOf(binding.value) === -1) {
            if(vnode.elm.parentElement){
               vnode.elm.parentElement.removeChild(vnode.elm)
            }
         }
      }

   //    let roles = store.state.user.roles;
   //    if(binding.arg == "role"){
   //       if(roles && roles.indexOf(binding.value) === -1) {
   //          if(vnode.elm.parentElement){
   //             vnode.elm.parentElement.removeChild(vnode.elm)
   //          }
   //       }
   //    }
    }
);